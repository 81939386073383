.wi-input-container.checkbox .wi-label {
position: relative;
float: left;
top: 0;
left: 0;
width: calc(100% - var(--input-border-left) - var(--input-border-right));
margin-left: var(--input-border-left);
margin-right: var(--input-border-right);
margin-top: 0;
font-size: var(--input-label-font-size);
color: var(--input-label-color);
line-height: var(--input-label-line-height);
height: var(--input-label-line-height);
}
.wi-input-container .wi-checkbox-list {
width: calc(100% - 32px - var(--input-border-left) - var(--input-border-right));
padding: 0px calc(16px + var(--input-border-right)) 0px calc(16px + var(--input-border-left));
}
.wi-input-container .wi-checkbox-container {
width: 100%;
margin-top: calc(var(--spacer) * 4);
}
.wi-input-container .wi-checkbox-container:first-child {
margin: 0;
}
.wi-input-container .wi-checkbox-container .wi-checkbox {
width: 24px;
height: 24px;
line-height: 24px;
border-radius: var(--input-border-radius);
border: var(--input-border-bottom) solid var(--input-border-color);
background: var(--input-bg-color);
transition: .1s;
cursor: pointer;
}
.wi-input-container .wi-checkbox-container .wi-checkbox:hover {
border: var(--input-border-bottom) solid var(--input-border-focus);
}
.wi-input-container .wi-checkbox-container .wi-checkbox:checked {
border: var(--input-border-bottom) solid var(--input-border-focus);
}
.wi-input-container .wi-checkbox-container .wi-checkbox-label {
width: calc(100% - 30px);
margin-left: 6px;
margin-top: calc((24px - var(--text-line-height)) / 2);
line-height: 24px;
font-size: var(--input-label-font-size);
font-weight: var(--input-label-weight);
font-family: var(--input-tx-family);
color: var(--input-label-color);
cursor: pointer;
} 
.wi-input-container .wi-checkbox-container .wi-checkbox-icon {
position: absolute;
width: calc(22px + (var(--input-border-bottom) * 2));
height: calc(22px + (var(--input-border-bottom) * 2));
line-height: calc(22px + (var(--input-border-bottom) * 2));
text-align: center;
color: var(--input-border-focus);
font-size: 13px;
pointer-events: none;
z-index: 2;
transition: .1s;
opacity: 0;
}
.wi-input-container .wi-checkbox-container:has(.wi-checkbox:checked) .wi-checkbox-icon {
opacity: 1;
}